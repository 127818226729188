@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* Definições do Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Estilos base */
body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  color: #ffffff;
  background-color: #121212;
}

/* Estilos customizados */
.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

/* Responsividade */
@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem !important;
  }
  
  h2 {
    font-size: 1.75rem !important;
  }
}

/* Classes de utilidade */
.transition-all {
  transition: all 0.3s ease;
}

.hover\:opacity-90:hover {
  opacity: 0.9;
}
